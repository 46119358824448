import { Component, OnDestroy, OnInit, computed, effect, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Subject } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressBarModule } from 'primeng/progressbar';
import { AgoraService } from '../services/agora/agora.service';
import { LikeButtonComponent } from '../like-button/like-button.component';
import { RoomEventsService } from '../services/room-events/room-events.service';
import { EmailEntryComponent } from '../email-entry/email-entry.component';
import { ConnectionsService } from '../services/connections/connections.service';
import { QuestionComponent } from '../question/question.component';
import { ShareLinkModalComponent } from '../share-link-modal/share-link-modal.component';
@Component({
  selector: 'connections',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    ProgressBarModule,
    LikeButtonComponent,
    EmailEntryComponent,
    QuestionComponent,
    ShareLinkModalComponent
  ],
  template: `
    <div class="bg-black w-full h-full">
      <div class="fixed top-0 left-0 w-screen h-screen object-cover z-0 blur-3 bg-black" id="remote-video"></div>
      <div class="flex justify-center h-full">
        <div class="flex flex-col w-full">
          <div class="main-bg-color flex flex-col">
            <p class="text-center font-semibold text-white z-50 main-bg-color">{{ status() }} <span *ngIf="shareLinkModalIntId && status() !== 'Connected'"> {{ countDown()}}'s</span></p>
            <!-- <p *ngIf="shareLinkModalIntId">{{ countDown() }}'s</p> -->
          </div>

          <div class="relative w-full h-full">
            <div *ngIf="status() !== 'Connected'" class="fixed top-0 left-0 w-screen h-screen object-cover z-10 bg-black"></div>
            <div class="flex flex-col absolute w-full justify-center items-center bottom-0 mb-20 px-20">
              @if (status() === 'Connected') {
                <div class="w-full max-w-80">
                  <div class="flex justify-center w-full mb-32" *ngIf="isUserTurn()">
                    <question class="w-full" [currentQuestion]="currentQuestion()"></question>
                  </div>                              
                  <div class="button-group flex justify-between w-full">
                    <img *ngIf="displayedBtn() === 'end'" (click)="endCall()" class="w-10" src="images/phone-red.svg" alt="end call icon">
                    <like-button [hasSentLike]="!!sentLike()" [currentUser]="user" [roomId]="roomId"></like-button>
                  </div>              
                </div>
              }
            </div>
          </div>

          <!-- <p-progressBar [value]="value" class="w-full h-4 my-2 px-2" /> -->
          <ng-container *ngIf="isModalVisible">
            <lib-email-entry (closeModalEvent)="onCloseModal($event)" [headerTitle]="headerTitle"></lib-email-entry>
          </ng-container>

          <ng-container *ngIf="displayShareLinkModal">
            <share-link-modal [visible]="displayShareLinkModal" (closeModalEvent)="closeShareLinkModal()"></share-link-modal>
          </ng-container>
        </div>
      </div>
    </div>
  `,
    styles: [`
      .blur-3 {
        -o-filter: blur(15px);
        filter: blur(15px);
        object-fit: cover;
      }
      .blur-2 {
        -o-filter: blur(10px);
        filter: blur(10px);
        object-fit: cover;
      }
      .blur-1 {
        -o-filter: blur(5px);
        filter: blur(5px);
        object-fit: cover;
      }
    `],
})
export class ConnectionsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  displayShareLinkModal = false;
  allCurrentUsers: { roomId: string, user: { username: string, email: string } }[] = [];
  intId: any = null;
  shareLinkModalIntId: any = null;
  value = 65;
  hasConnected = false;
  isModalVisible = false;
  headerTitle = '';
  roomId = '';
  countDownState = signal({
    num: 20,
  });


  // Services
  connectionsService = inject(ConnectionsService);
  roomEventsService = inject(RoomEventsService);
  agoraService = inject(AgoraService);
  
  // Computed
  displayedBtn = computed(() => this.agoraService.displayedBtn());
  sentLike = computed(() => this.roomEventsService.state().sentLike);
  isLiked = computed(() => this.roomEventsService.state().isLiked);
  isUserTurn = computed(() => this.roomEventsService.state().isUserTurn);
  currentQuestion = computed(() => this.roomEventsService.state().currentQuestion);
  countDown = computed(() => this.countDownState().num);

  // Computed (Slices of state)
  status = computed(() => {
    const status = this.agoraService.status();
    if (status === 'Connected' && !this.hasConnected) {
      this.value = 65;
      this.agoraService.setRemoteVideoElement(document.getElementById('remote-video') as HTMLElement);
      this.decrementTimer();
      this.hasConnected = true;
    }
    if (status === 'Connected' && this.hasConnected) {
      clearInterval(this.shareLinkModalIntId);
    }
    if (status.includes('Searching')) {
      this.startSearchCountdown();
    }
    return this.agoraService.status();
  });

  // Effects
  isMutual = effect(() => {
    if (this.isLiked() && this.sentLike()) {
      clearInterval(this.intId);
      this.updateBlur('blur-0', true);
    }
  });

  agoraItems = {
    token: '',
    appId: '',
    channel: '',
    uid: ''
  }
  user = {
    username: '',
    email: ''
  };
  // rtc: any = {
  //   client: null,
  //   localAudioTrack: null,
  //   localVideoTrack: null
  // }

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly route:  ActivatedRoute,
  ) {}

  decrementTimer() {
    this.intId = setInterval(async () => {
      if (this.value > 0) {
        this.value--;
        const blurClass = this.determineBlur(this.value);
        this.updateBlur(blurClass);
      } else if (this.value > -1) {
        clearInterval(this.intId);
        console.log('end call')
        // await this.endCall();
      }
    }, 1000);
  }

  determineBlur(value: number) {
    if (value <= 30) {
      return 'blur-1';
    } else if (value <= 60) {
      return 'blur-2';
    } else if (value <= 90) {
      return 'blur-3';
    }
    return '';
  }

  updateBlur(newClass: string, removeBlur = false) {
    const video = document.getElementById('remote-video') as HTMLImageElement;
    if (removeBlur) {
      const currentClasses = video.className.split(' ');
      if (currentClasses.length) {
        const foundClass = currentClasses.find(item => item.includes('blur'));
        video.classList.remove(foundClass || '');
      }
      return;
    }

    if (video && newClass) {
      // get the current class
      const currentClasses = video.className.split(' ');
      if (currentClasses.every(s => s !== newClass)) {
      // remove the blur class
        if (currentClasses.length) {
          const foundClass = currentClasses.find(item => item.includes('blur'));
          video.classList.remove(foundClass || '');
          // currentClasses.map(item => video.classList.remove(item));
          video.classList.add(newClass);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();  // Emit a value to trigger unsubscription
    this.destroy$.complete();
    clearInterval(this.intId);
  }

  async ngOnInit(): Promise<void> {
    // console.log(this.testing())
    this.roomId = this.route.snapshot.paramMap.get('id') || '';
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.allCurrentUsers = await firstValueFrom(this.roomEventsService.allUsersInRoom(this.roomId || ''));

    if (!this.user.username || !this.user.email) {
      this.headerTitle = `Invited by ${this.allCurrentUsers[0].user.username}`;
      this.isModalVisible = true;
      return;
    }
    this.agoraItems = {
      ...JSON.parse(localStorage.getItem('agoraItems') || '{}'),
      channel: this.route.snapshot.paramMap.get('id') || ''
    };
    
    if (this.user.username && this.user.email) {
      this.roomEventsService.room$.next(this.roomId);

      if (!this.allCurrentUsers.find(item => item.user.email === this.user.email)) {
        const response = await this.connectionsService.getAgoraToken(this.user.username, this.user.email, this.roomId);
        console.log('asdfsa', response)
        this.agoraItems = {
          ...response,
          channel: this.roomId
        };
      }
      // is this important?
      // localStorage.setItem('agoraItems', JSON.stringify({ appId: this.agoraItems.appId, channel: this.agoraItems.channel, token: this.agoraItems.token }));
      setTimeout(async () => {
        this.agoraService.setRTC();
        await this.agoraService.startCall(
          this.agoraItems.appId,
          this.agoraItems.channel,
          this.agoraItems.token,
          this.agoraItems.uid
        );
      }, 2000);
    } else {
      this.router.navigate(['']);
    }
  }

  async onCloseModal(event: { email: string, username: string }) {
    const { email, username } = event;
    const response = await this.connectionsService.getAgoraToken(username, email, this.roomId);
    this.agoraService.setRTC();
    await this.agoraService.startCall(
      response.appId,
      this.roomId,
      response.token,
      response.uid
    );
    this.roomEventsService.room$.next(this.roomId);
    this.isModalVisible = false;
  }


  async endCall() {
    await this.agoraService.endCall();
    this.router.navigate(['connections']);
  }

  async refreshToken(): Promise<string> {
    const response = await firstValueFrom(this.http.post<{token: string}>('/refresh-agora-token', {
      channel: this.agoraItems.channel,
      uid: this.agoraItems.uid
    }));
    return response.token;
  }

  startSearchCountdown() {
    if (this.shareLinkModalIntId) return;
    this.shareLinkModalIntId = setInterval(() => {
      const current = this.countDown();
      this.countDownState.update(state => ({ num: state.num === 0 ? 0 : state.num - 1 }));
      if (current < 1) {
        clearInterval(this.shareLinkModalIntId);
        this.displayShareLinkModal = true;
      }
    }, 1000);
  }

  closeShareLinkModal() {
    console.log('blah')
    this.displayShareLinkModal = false;
  }
}
