import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'share-link-modal',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
  ],
  template: `
    <div class="mx-2">
      <p-dialog header="Header" [(visible)]="visible" [modal]="true" [style]="{ width: '30rem' }" (onHide)="closeModal()">
        <ng-template pTemplate="header">
          <div class="inline-flex items-center justify-content-center gap-2">
            <img src="./images/ghost.svg" alt="ghost icon" class="w-10 h-10">
            <span class="font-bold white-space-nowrap">It's a ghost town.</span>
          </div>
        </ng-template>
        <div class="flex flex-col px-7 mb-8">
          <p class="font-semibold">Not many users at the moment.</p>
          <div class="flex flex-row gap-x-2 relative">
            <p class="font-semibold"><span class="underline">Copy</span> the link and share with a friend!</p>
            <img src="./images/copy.svg" alt="copy icon" class="w-5 h-5 cursor-pointer" (click)="copyLink()" /> 
            <div class="rounded absolute right-[5.4rem] top-5 bg-white border border-black" *ngIf="isToastShowing">
              <p class="p-1">Copied!</p>
            </div>
          </div>
        </div>
      </p-dialog>
    </div>
  `,
})
export class ShareLinkModalComponent {
  @Input() visible = false;
  @Output() closeModalEvent = new EventEmitter<{username: string, email: string}>();
  headerTitle = "Share this link with your friends!";
  isToastShowing = false;
  constructor(private router: Router) {}
  copyLink() {
    const fullUrl = window.location.origin + this.router.url;
    navigator.clipboard.writeText(fullUrl);
    this.showToast();
  }

  showToast() {
    this.isToastShowing = true;
    setTimeout(() => {
      this.isToastShowing = false;
    }, 600);
  }

  closeModal() {
    this.closeModalEvent.emit();
    this.visible = false;
  }
}
