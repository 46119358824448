import { computed, Injectable, signal } from '@angular/core';
import AgoraRTC from 'agora-rtc-sdk-ng';

export interface AgoraState {
  status: string;
  displayedBtn: string;
}

@Injectable({
  providedIn: 'root'
})
export class AgoraService {
  // status$ = new BehaviorSubject('Preview Mode');
  // displayedBtn$ = new BehaviorSubject('start');

  // Signal initial state
  state = signal<AgoraState>({
    status: 'Preview Mode',
    displayedBtn: 'start'
  });

  // Selectors (slices of state)
  status = computed(() => this.state().status);
  displayedBtn = computed(() => this.state().displayedBtn);

  rtc: any = {
    client: null,
    localAudioTrack: null,
    localVideoTrack: null,
    remoteVideoTrack: null
  };
  // constructor() { }

  async endCall(): Promise<void> {
    this.state.update(state => ({ ...state, displayedBtn: 'cancel' }));
    if (this.status() === 'Connected') {
      await this.rtc.client.unpublish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);
      await this.rtc.client.leave();
    }

    if (this.rtc.localAudioTrack && this.rtc.localVideoTrack) {
      this.rtc.localAudioTrack.close();
      this.rtc.localVideoTrack.close();
    }
    
    
    
    // this.rtc = {
    //   client: null,
    //   localAudioTrack: null,
    //   localVideoTrack: null,
    //   remoteVideoTrack: null
    // };
  }

  private async joinCall(appId: string, channel: string, token: string, uid: string): Promise<void> {
    await this.rtc.client.join(
      appId,
      channel,
      token,
      uid
    );
  }

  async startCall(appId: string, channel: string, token: string, uid: string) {
    this.state.update(state => ({ ...state, status: 'Searching for a match...', displayedBtn: 'cancel' }));
    await this.joinCall(
      appId,
      channel,
      token,
      uid
    );
    await this.accessCameraAndMicrophone();
    this.publishTracks();
  }

  private publishTracks() {
    this.rtc.client.publish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);
  }

  async accessCameraAndMicrophone(): Promise<void> {
    this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
      encoderConfig: '720p_1',
    });
  }

  setLocalVideoElement(localVideo: HTMLElement): void {
    this.rtc.localVideoTrack.play(localVideo);
  }

  setRemoteVideoElement(remoteVideo: HTMLElement): void {
    this.rtc.remoteVideoTrack.play(remoteVideo);
  }

  setRTC() {
    this.rtc.client = AgoraRTC.createClient({ 
      mode: 'rtc', 
      codec: 'vp8',
      websocketRetryConfig: {
        maxRetryCount: 3,
        timeout: 1000,
        timeoutFactor: 0,
        maxRetryTimeout: 2000
      }
    });

    // When you receive the token-privilege-will-expire callback, request a new token from the server and call renewToken to pass the new Token to the SDK
    this.rtc.client.on("token-privilege-will-expire", async () => {
      console.log('Token will expire');
      // const token = await this.refreshToken();
      // await this.rtc.client.renewToken(token);
    });
    // When you receive the token-privilege-did-expire callback, request a new token from the server and call join to rejoin the channel.
    this.rtc.client.on("token-privilege-did-expire", async () =>{
      console.log("Token did expire");
      // const token = await this.refreshToken();
      // await this.rtc.client.join(this.agoraItems.appId, this.agoraItems.channel, token, this.agoraItems.uid);
    });
 

    this.rtc.client.on('user-published', async (user: any, mediaType: any) => {
      await this.rtc.client.subscribe(user, mediaType);
      console.log('subscribe success', mediaType);
      if (mediaType === 'video') {
        // this.status = 'Connecting...';
        // this.displayedBtn = 'connecting'
        this.rtc.remoteVideoTrack = user.videoTrack;
        // console.log('remoteVideoTrack', remoteVideoTrack);
        // this.status = 'Connected';
        this.state.update(state => ({ ...state, status: 'Connected', displayedBtn: 'end' }));
        // setTimeout(() => {
        //   const remoteVideoElement = document.getElementById('remote-video');
        //   if (remoteVideoElement) {
        //     // remoteVideoElement.appendChild(remoteVideoTrack.play());
        //     remoteVideoTrack.play(remoteVideoElement);
        //     this.displayedBtn = 'end';
        //     this.decrementTimer();
        //   }
        // }, 1000);
        // document.getElementById('remote-video').appendChild(remoteVideoTrack.play());
      }
      if (mediaType === 'audio') {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });

    this.rtc.client.on('user-unpublished', async (user: any) => {
      console.log('unpublish');
      if (user) {
        this.state.update(state => ({ ...state, status: 'Call Ended' }));
        // const remoteVideoElement = document.getElementById('remote-video');
        // if (remoteVideoElement) {
        //   remoteVideoElement.remove();
        //   await this.endCall();
        // }
      }
    });
    
  
    // this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    // this.rtc.client.join(this.token, this.rtc.localAudioTrack, this.rtc.localVideoTrack);
  }
}
