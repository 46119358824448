import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  initiateCall(username: string, email: string): Promise<{token: string, appId: string, channel: string, uid: string}> {
    return firstValueFrom(this.http.post<{token: string, appId: string, channel: string, uid: string}>('/initiateCall', {username, email}))
  }

  getAgoraToken(username: string, email: string, channel: string): Promise<{token: string, appId: string, uid: string}> {
    return firstValueFrom(this.http.post<{token: string, appId: string, uid: string}>('/getAgoraToken', {username, email, channel}))
  }
}
