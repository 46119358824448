import { Component, computed, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { Question, RoomEventsService } from '../services/room-events/room-events.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'question',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule
  ],
  template: `
    <div class="w-full rounded bg-gray-800 py-3">
      <div class="flex flex-col gap-y-2 text-gray-200 mx-4 ">
        <span class="font-sembibold tracking-wide text-lg">{{ currentQuestion.type }}</span>
        <span class="">{{ currentQuestion.question }}</span>
      </div>
    </div>
  `
})
export class QuestionComponent implements OnInit, OnDestroy {
  @Input() currentQuestion = {
    question: '',
    index: 0,
    seen: false,
    type: ''
  };
  intId: any;
  roomId = computed(() => this.roomEventsService.state().roomId);
  allUsers = computed(() => this.roomEventsService.state().usersInRoom);
  userId = JSON.parse(localStorage.getItem('user') || '{}')?.email || '';
  
  constructor(private readonly roomEventsService: RoomEventsService) {}

  ngOnInit() {
    this.intId = setInterval(async () => {
      await this.toggleTurn();
    }, 30000);
  }

  async toggleTurn() {
    if (this.roomEventsService.state().isUserTurn) {
      const allQuestions = await firstValueFrom(this.roomEventsService.allQuestions(this.roomId()));
      allQuestions.splice(this.currentQuestion.index, 1, { ...this.currentQuestion, seen: true });
      const selectedQuestion = this.selectRandomQuestion([ ...allQuestions]);
      const allUsers = this.allUsers();
      const turn = allUsers.find(user => user.email !== this.userId)?.email || '';
      const p = allUsers.map(user => {
        return [
          this.roomEventsService.updateTurnAndQuestion(this.roomId(), user.email, selectedQuestion, turn),
          this.roomEventsService.updateQuestionList(this.roomId(), allQuestions)
        ];
      }).flat();

      await Promise.all(p);
    }
  }

  selectRandomQuestion(questions: Question[]): Question {
    questions = [ ...questions].filter(q => q.seen === false);
    const randomIndex = Math.floor(Math.random() * questions.length);
    return questions[randomIndex];
  }

  ngOnDestroy() {
    clearInterval(this.intId);
  }
}
