import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
// import { environment } from "@tda-web/environments";
import { Observable, finalize, map } from "rxjs";
import { LoadingService } from "@tda-web/ui";
import { environment } from "../environments/environment";

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private readonly loadingService: LoadingService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = window.localStorage.getItem("OMEGA_TOKEN");
    this.loadingService.isLoading$.next(true);
    
    if (token) {
      return next.handle(
        req.clone({
          url: environment.apiUrl + req.url,
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        })
      ).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const token = event.headers.get("Authorization")?.split(' ')[1];
            if (token) {
              window.localStorage.setItem("OMEGA_TOKEN", token);
            }
          }
          return event;
        }),
        finalize(() => this.loadingService.isLoading$.next(false))
      )
    }
    return next.handle(req.clone({ url: environment.apiUrl + req.url }))
    .pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const token = event.headers.get("Authorization")?.split(' ')[1];
          if (token) {
            window.localStorage.setItem("OMEGA_TOKEN", token);
          }
        }
        return event;
      }),
      finalize(() => this.loadingService.isLoading$.next(false))
    );
  }
}