import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'lib-email-entry',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    FloatLabelModule,
    FormsModule,
  ],
  template: `
    <div class="mx-2">
      <p-dialog [header]="headerTitle" [modal]="true" [(visible)]="visible" [style]="{ width: '30rem' }" (onHide)="closeModal()">
        <div class="p-4">
          <p class="p-text-secondary block">Stay up to date for news on the official release!</p>
          <p class="p-text-secondary block mb-1">Spam is for eating. Not to bug ya'.</p>
          <p class="p-text-secondary block text-sm mb-3">We won't abuse your trust!</p>

          <form [formGroup]="emailForm" class="mt-8 mb-4">
            <div class="flex flex-col gap-8">
              <p-floatLabel>
                <input class="pl-3 border" pInputText id="username" formControlName="username" autocomplete="off" />
                <label for="username">Username</label>
              </p-floatLabel>

              <p-floatLabel>
                <input class="pl-3 border" pInputText id="email" formControlName="email" autocomplete="off" />
                <label for="email">Email</label>
              </p-floatLabel> 
            </div>
          </form>
          <div class="flex justify-between gap-2">
            <p-button class="cursor-pointer text-red-500" label="Cancel" severity="danger" (click)="closeModal()" />
            <p-button class="cursor-pointer text-green-500" label="I'm Ready!" (click)="connect()" />
          </div>
        </div>
      </p-dialog>
    </div>
  `,
  styles: ``,
})
export class EmailEntryComponent implements OnInit {
  @Input() headerTitle = "Let's meet some folks :)";
  @Output() closeModalEvent = new EventEmitter<{username: string, email: string}>();
  emailForm = this.formBuilder.group({
    username: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });
  visible = true;

  constructor(
    private readonly formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.emailForm.patchValue(user);
  }

  closeModal() {
    this.closeModalEvent.emit();
    this.visible = false;
  }

  async connect() {
    const { username, email } = this.emailForm.value;
    if (this.emailForm.invalid) {
      return;
    }
    this.closeModalEvent.emit({ username: username || '', email: email || '' });
    this.visible = false;
    localStorage.setItem('user', JSON.stringify({ username, email }));
  }
}
