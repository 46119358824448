import { Route } from '@angular/router';
import { ConnectionsComponent, HomeComponent, PreviewConnections, RoomValidator } from '@tda-web/ui';

export const appRoutes: Route[] = [
  {
    path: 'connections',
    pathMatch: 'full',
    component: PreviewConnections,
  },
  {
    path: 'connections/:id',
    pathMatch: 'full',
    component: ConnectionsComponent,
    resolve: {
      roomValidator: RoomValidator
    }
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];
