import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, tap } from 'rxjs';
import { RoomEvent, RoomEventsService } from '../room-events/room-events.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class NotificationBarService {
  message$ = new BehaviorSubject<string>('');
  userId = JSON.parse(localStorage.getItem('user') || '{}')?.email || '';
  constructor(private readonly roomEventsService: RoomEventsService) {}

  likeEvent$ = combineLatest([
    this.roomEventsService.events$,
    this.roomEventsService.room$,
    this.roomEventsService.notifiedOfBeingLiked$,
  ])
  .pipe(
    takeUntilDestroyed(),
    filter(([events, roomId, notifiedOfBeingLiked]) => notifiedOfBeingLiked === false),
    tap(([events, roomId]) => {
      const likeEvent = events.find((event: RoomEvent) => event.isLiked);
      if (likeEvent) {
        this.message$.next(`${likeEvent.username} liked you!`);
        this.roomEventsService.notifiedOfBeingLiked$.next(true);
      }
    })
  ).subscribe();
}
