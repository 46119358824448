import { Injectable } from '@angular/core';
import {
  Router, 
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { RoomEventsService } from '../services/room-events/room-events.service';
import { NotificationBarService } from '../services/notification-bar/notification-bar.service';

@Injectable({
  providedIn: 'root'
})
export class RoomValidator implements Resolve<boolean> {
  constructor(
    private router: Router,
    private roomEventsService: RoomEventsService,
    private notifyService: NotificationBarService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const id = route.paramMap.get('id');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
  
    return combineLatest([
      this.roomEventsService.doesRoomExist(id || ''),
      this.roomEventsService.allUsersInRoom(id || '')
    ]).pipe(
      map(([exists, users]) => {
        if (!exists) {
          this.router.navigate(['connections']);
          this.notifyService.message$.next('Room not found');
        }

        if (users.length > 1 && !this.isInRoom(users, user)) {
          this.router.navigate(['connections']);
          this.notifyService.message$.next('Room full');
        }
        return exists && users.length < 2;
      })
    );
  }

  private isInRoom(users: {roomId: string, user: { username: string, email: string } }[], user: {username: string, email: string}): boolean {
    return !!users.find(u => {
      return u.user?.email?.trim() === user?.email?.trim();
    });
  }
}
