const baseUrl = 'https://tda-server3a-df14fef2f89d.herokuapp.com/api/v1';
export const environment = {
  production: true,
  apiUrl: baseUrl,
  firebaseConfig: {
    apiKey: "AIzaSyA4LrewB8i-avc6g2hHgEfXNKGiUpih0qc",
    authDomain: "dass-9b33e.firebaseapp.com",
    databaseURL: "https://dass-9b33e-default-rtdb.firebaseio.com",
    projectId: "dass-9b33e",
    storageBucket: "dass-9b33e.appspot.com",
    messagingSenderId: "119914692167",
    appId: "1:119914692167:web:e874edb5d2ea4ab5ca7b62",
    measurementId: "G-PG6DH1FH6B"
  }
  // Other production environment variables if you have
 };

 export const apis = {
  initiateCall: `${baseUrl}/initiateCall`,
 };
 