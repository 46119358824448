import { Component } from '@angular/core';
import { NotificationBarService } from '../services/notification-bar/notification-bar.service';
import { CommonModule } from '@angular/common';
import { delay, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'notification-bar',
  standalone: true,
  imports: [
    CommonModule
  ],
  template: `
    <div *ngIf="msg$ | async as msg" class="bg-red-500 text-white text-center p-2 z-50">
      {{ msg }}
    </div>
  `,
})
export class NotificationBarComponent {
  msg$ = this.notifyService.message$;
  constructor(private readonly notifyService: NotificationBarService) {
    // hide message after 3 seconds
    this.msg$.pipe(
      delay(5000),
      takeUntilDestroyed(),
      tap(() => this.notifyService.message$.next(''))
    ).subscribe();
  }
}
