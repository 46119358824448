import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'explanation-carousel',
  standalone: true,
  imports: [
    CommonModule,
    CarouselModule,
    ButtonModule,
    TagModule
  ],
  template: `
    <p-carousel 
      [value]="products" 
      [numVisible]="3" 
      [numScroll]="3" 
      [circular]="true"
      [responsiveOptions]="responsiveOptions"
      autoplayInterval="8000">
        <ng-template let-product pTemplate="item">
          <div class="border-1 surface-border border-round m-2 p-3 h-full flex flex-col">
            <!-- <div class="mb-3">
              <div class="relative mx-auto">
                <img 
                  src="https://primefaces.org/cdn/primeng/images/demo/product/{{ product.image }}" 
                  [alt]="product.name" 
                  class="w-full border-round" />
                <p-tag 
                  [value]="product.inventoryStatus" 
                  [severity]="getSeverity(product.inventoryStatus)" 
                  class="absolute" 
                  [ngStyle]="{ 'left.px': 5, 'top.px': 5 }" />
              </div>
            </div> -->
            <div class="mb-3">
              <p class="text-sm sm:text-3xl font-semibold">{{ product.description }}</p>
            </div>
            <div class="max-h-96 flex justify-center h-full">
              <div class="relative rounded-md w-full">
                <img [src]="product.src" [alt]="product.alt" class="w-full h-full object-cover" />
                <div class="rounded-md absolute inset-0 flex items-end justify-center bg-black bg-opacity-65">
                  <p 
                    id="explanation"
                    class="text-white  leading-tight sm:text-2xl font-semibold mx-4 md:mx-10 md:leading-normal">
                    {{ product.explanation }}
                  </p>
                </div>                
              </div>
            </div>
          </div>
        </ng-template>
    </p-carousel>  
  `,
  styles: [`
    .explanation {
      @media (min-width: 480px) {
        font-size: 0.875rem /* 14px */;
        line-height: 1.25rem /* 20px */;
      }
    }
    // .p-carousel-content {
    //   @apply flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-2;
    // }
  `],
})
export class ExplanationCarouselComponent {
  products = [
    {
      description: '1. Log In and Get Matched',
      explanation: "You'll be matched with a user through a video chat. But there's a twist - the screen is blurred initially, putting the focus on your conversation rather than appearances.",
      src: 'images/logo-sci.png',
      alt: 'logo image'
    },
    {
      description: '2. Engage with Ice Breakers',
      explanation: "To get the conversation flowing, fun and engaging ice breaker questions will pop up. These prompts are designed to help you and your match connect on a deeper level and enjoy a relaxed, low-pressure chat.",
      src: 'images/icebreaking-sci.png',
      alt: 'ice breaker image'
    },
    {
      description: '3. Gradual Reveal',
      explanation: "As time passes, the screen will gradually become clearer. This slow reveal keeps the emphasis on personality and connection, allowing you to form a bond without the usual visual biases.",
      src: 'images/chyka-blurs.png',
      alt: 'blurred image'
    },
    {
      description: '4. Mutual Like',
      explanation: "The screen will not come into complete focus until both parties click the like button. This mutual decision ensures that both of you are genuinely interested in taking the next step.",
      src: 'images/resized_two_hearts_image_standard.png',
      alt: 'two hearts'
    },
    {
      description: '5. Moving On',
      explanation: "If it's not mutual, you'll be matched with a new user. This way, you always have the opportunity to meet someone new and potentially find a meaningful connection.",
      src: 'images/resized_alarm_clock_image_standard.png',
      alt: 'alarm clock'
    }    
  ];
  responsiveOptions = [
    {
        breakpoint: '1250px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
    },
];


  getSeverity(status: string) {
    switch (status) {
      case 'INSTOCK':
        return 'success';
      case 'LOWSTOCK':
        return 'warning';
      case 'OUTOFSTOCK':
        return 'danger';
      default:
        return 'info';
    }
  }
}
