import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoomEventsService } from '../services/room-events/room-events.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'like-button',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="flex justify-center" (click)="likeUser()">
      <img *ngIf="!_hasSentLike" src="images/empty-heart.svg" alt="like button" class="cursor-pointer w-10" />
      <img *ngIf="_hasSentLike" src="images/filled-heart.svg" alt="like button" class="cursor-pointer w-10" />
    </div>
  `
})
export class LikeButtonComponent {
  _hasSentLike = false;
  @Input() currentUser: { username: string, email: string } = { username: '', email: '' };
  @Input() roomId = '';
  @Input() set hasSentLike(value: boolean) {
    console.log('hasSentLike', value)
    this._hasSentLike = value;
  }
  allCurrentUsers: { roomId: string, user: { username: string, email: string } }[] = [];
  // likeEvent = this.roomEventsService.likeEvent;
  constructor(private readonly roomEventsService: RoomEventsService) {}
  async likeUser() {
    const users = await firstValueFrom(this.roomEventsService.allUsersInRoom(this.roomId));
    const match = users.find(user => user.user.email !== this.currentUser.email);
  
    if (match) {
      await Promise.all([
        this.roomEventsService.likeUser(this.roomId || '', match.user.email),
        this.roomEventsService.sendLike(this.roomId || '', this.currentUser.email)
      ]);
      // await this.roomEventsService.likeUser(this.roomId || '', match.user.email);
    }
    // this.roomEventsService.likeUser('roomId', 'userId');
  }
}
