import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { Router } from '@angular/router';
import { ProgressBarModule } from 'primeng/progressbar';
import { ConnectionsService } from '../services/connections/connections.service';
import { AgoraService } from '../services/agora/agora.service';
import { QuestionComponent } from '../question/question.component';

@Component({
  selector: 'preview-connections',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    ProgressBarModule,
    QuestionComponent,
  ],
  template: `
    <div class="fixed top-0 left-0 w-screen h-screen object-cover z-0" id="local-video"></div>
    <div class="flex justify-center h-full z-50">
      <div class="flex flex-col w-full relative items-center text-white">
        <p class="text-center font-semibold main-bg-color w-full">{{ status }}</p>
        <div class="flex flex-row justify-center mt-2 absolute bottom-8">
          <p-button *ngIf="displayedBtn === 'start'" (onClick)="searchChannel()" class="cursor-pointer main-bg-color rounded text-white px-10 py-2 z-50" label="Start Call"></p-button>
          <p-button *ngIf="displayedBtn === 'cancel'" (onClick)="cancelCall()" class="cursor-pointer bg-yellow-500 rounded text-white px-10 py-2 z-50" label="Cancel Connection"></p-button>      
        </div>
      </div>
    </div>
  `,
})
export class PreviewConnections implements OnInit, OnDestroy, AfterViewInit {
  status = 'Preview Mode';
  displayedBtn: 'start' | 'cancel' | 'connecting' = 'start';
  user = JSON.parse(localStorage.getItem('user') || '{}');

  constructor(
    private readonly router: Router,
    private readonly connectionsService: ConnectionsService,
    private readonly agoraService: AgoraService
  ) {}

  ngOnInit() {
    if (!this.user.username || !this.user.email) {
      this.router.navigate(['']);
    }
  }

  async ngAfterViewInit(): Promise<void> {
    console.log('ngAfterViewInit')
    if (!this.user.username || !this.user.email) return;
    await this.preview();
  }

  ngOnDestroy() {
    if (!this.user.username || !this.user.email) return;
    if (this.agoraService.rtc.localAudioTrack && this.agoraService.rtc.localVideoTrack) {
      this.agoraService.rtc.localAudioTrack.close();
      this.agoraService.rtc.localVideoTrack.close();
    }
  }

  async searchChannel(): Promise<void> {
    const { username, email } = JSON.parse(localStorage.getItem('user') || '{}');
    const response = await this.connectionsService.initiateCall(username, email);
    localStorage.setItem('agoraItems', JSON.stringify({ appId: response.appId, channel: response.channel, token: response.token, uid: response.uid }));
    this.router.navigate(['connections', response.channel]);
  }

  cancelCall() {
    this.displayedBtn = 'start';
    this.status = 'Preview Mode';
  }

  async preview(): Promise<void> {
    const localVideo = document.getElementById('local-video') as HTMLElement;
    await this.agoraService.accessCameraAndMicrophone();
    this.agoraService.setLocalVideoElement(localVideo);
    // await  this.rtc.localVideoTrack.setEnabled(true);
  }
}