import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { NotificationBarComponent } from '@tda-web/ui';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';


@Component({
  standalone: true,
  imports: [
    RouterModule,
    DialogModule,
    CommonModule,
    NotificationBarComponent,
  ],
  selector: 'app-root',
  template: `
    <notification-bar></notification-bar>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  ngOnInit() {
    if (environment.production) {
      this.redirectToHttps();
    }
  }

  private redirectToHttps() {
    if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }
  }
}
