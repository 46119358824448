import { Injectable, inject, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subject, filter, map, switchMap, tap } from 'rxjs'
import { Firestore, collectionData, collection, doc, docData } from '@angular/fire/firestore';
import { setDoc } from 'firebase/firestore';
import { getDatabase, ref, push } from 'firebase/database';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface Question {
  index: number;
  question: string;
  seen: boolean;
  type: string;
}

export interface RoomEvent {
  isLiked: boolean;
  sentLike: boolean;
  username: string;
  isMutual: boolean;
  notifiedOfBeingLiked: boolean;
  currentQuestion: Question;
  isUserTurn: boolean;
  roomId: string;
  usersInRoom: { username: string, email: string }[];
}

@Injectable({
  providedIn: 'root'
})
export class RoomEventsService {
  firestore: Firestore = inject(Firestore);
  room$ = new Subject<string>();
  userId$ = new Subject<string>();
  notifiedOfBeingLiked$ = new BehaviorSubject<boolean>(false);
  state = signal<RoomEvent> ({
    isLiked: false,
    sentLike: false,
    isMutual: false,
    username: JSON.parse(localStorage.getItem('user') || '{}')?.username || '',
    notifiedOfBeingLiked: false,
    usersInRoom: [],
    roomId: '',
    currentQuestion: {
      index: 0,
      question: '',
      seen: false,
      type: ''
    },
    isUserTurn: false
  });

  events$: Observable<any> = this.room$.pipe(
    tap(roomId => {
      this.state.update(state => ({ ...state, roomId }))
    }),
    filter(roomId => !!roomId),
    switchMap(roomId => this.roomSubscription(roomId))
  );

  roomEvents$ = this.events$.pipe(
    takeUntilDestroyed(),
    tap(events => {
      const liked = !!events.find((event: RoomEvent) => event.isLiked);
      const sentLike = !!events.find((event: RoomEvent) => event.sentLike);
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      console.log('events', events)

      this.state.update(state => ({ 
        ...state, 
        isLiked: liked,
        sentLike: sentLike,
        isUserTurn: events?.[0]?.turn === user.email,
        currentQuestion: events?.[0]?.question || ''
      }));
      return events;
    })
  ).subscribe();

  allUsersInRoom$ = this.room$.pipe(
    takeUntilDestroyed(),
    switchMap(roomId => this.allUsersInRoom(roomId)),
    tap(users => {
      this.state.update(state => ({ ...state, usersInRoom: users.map(user => user.user) }));
    })
  ).subscribe();
  
  private roomSubscription(roomId: string): Observable<any> {
    const userId = JSON.parse(localStorage.getItem('user') || '{}')?.email || '';
    return collectionData(collection(this.firestore, `rooms/${roomId}/${userId}`));
  }

  // setQuestionAsSeen(roomId: string, currentUserId: string): void {}

  async likeUser(roomId: string, matchUserId: string): Promise<void> {
    const roomRef = doc(this.firestore, `rooms/${roomId}/${matchUserId}/events`);
    const username = JSON.parse(localStorage.getItem('user') || '{}')?.username || '';
    const key = await this.generateUniqueKey();
    console.log('likeUser', key);
    await setDoc(roomRef, { isLiked: true, username }, { merge: true });
  }

  async notifiedOfBeingLiked(roomId: string): Promise<void> {
    const currentUserId = JSON.parse(localStorage.getItem('user') || '{}')?.email || '';
    console.log('notifiedOfBeingLiked', roomId, currentUserId)
    const roomRef = doc(this.firestore, `rooms/${roomId}/${currentUserId}/events`);
    // const key = await this.generateUniqueKey();
    // console.log('addUserNotifiedOfBeingLiked', key);
    await setDoc(roomRef, { likeNotified: true }, { merge: true });
  }

  async sendLike(roomId: string, currentUserId: string): Promise<void> {
    const roomRef = doc(this.firestore, `rooms/${roomId}/${currentUserId}/events`);
    const key = await this.generateUniqueKey();
    console.log('sentLike', key);
    await setDoc(roomRef, { sentLike: true, key }, { merge: true });
  }

  doesRoomExist(roomId: string): Observable<boolean> {
    const roomRef = doc(this.firestore, `rooms/${roomId}`);
    return docData(roomRef).pipe(map(doc => {
      return doc !== undefined;
    }));
  }

  allUsersInRoom(roomId: string): Observable<{ roomId: string, user: { username: string, email: string } }[]> {
    const roomRef = doc(this.firestore, `rooms/${roomId}`);
    return docData(roomRef).pipe(
      map(doc => {
        if (!doc) {
          return [];
        }
        return doc?.['users'] || [];
      })
    );
  }

  allQuestions(roomId: string): Observable<Question[]> {
    const roomRef = doc(this.firestore, `rooms/${roomId}`);
    return docData(roomRef).pipe(
      map(doc => {
        if (!doc) {
          return [];
        }
        return doc?.['questions'] || [];
      })
    );
  }

  generateUniqueKey() {
    // Initialize Firebase Database (Assuming Firebase has been initialized elsewhere in your project)
    const database = getDatabase();
  
    // Reference to your data location, for example, 'posts'
    const postsRef = ref(database, 'posts');
  
    // Generate a new child location using a unique key and get the key
    const newPostRef = push(postsRef);
    const uniqueKey = newPostRef.key;
  
    // Return the unique key
    return uniqueKey || '';
  }

  updateTurnAndQuestion(roomId: string, email: string, question: Question, turn: string): Promise<void> {
    const roomRef = doc(this.firestore, `rooms/${roomId}/${email}/events`);
    return setDoc(roomRef, { question, turn }, { merge: true });
  }

  updateQuestionList(roomId: string, questions: Question[]): Promise<void> {
    const roomRef = doc(this.firestore, `rooms/${roomId}`);
    return setDoc(roomRef, { questions }, { merge: true });
  }
}
