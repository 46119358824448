import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { EmailEntryComponent } from '../email-entry/email-entry.component';
import { Router } from '@angular/router';
import { ExplanationCarouselComponent } from '../explanation-carousel/explanation-carousel.component';

@Component({
  selector: 'home',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    EmailEntryComponent,
    ExplanationCarouselComponent
  ],
  template: `
      <div class="relative">
        <img src="images/couple.jpg" class="w-screen h-screen object-cover">
        <div class="absolute z-10 left-0 top-0 w-12 ml-5 mt-5 cursor-pointer">
          <img src="images/logo.png" alt="logo">
        </div>
        
        <div class="absolute z-10 bottom-0 left-0 mb-5 ml-5 md:hidden">
          <p class="text-white text-5xl font-bold font-['Space Grotesk'] tagline-sm">Meet Real People</p>
          <p class="text-white text-5xl font-bold font-['Space Grotesk'] tagline-sm">In Real Time</p>
        </div>

        <div class="absolute z-10 bottom-0 left-0 mb-5 ml-5 hidden md:block lg:hidden">
          <p class="text-white text-5xl font-bold font-['Space Grotesk'] tagline-md">Meet Real People</p>
          <p class="text-white text-5xl font-bold font-['Space Grotesk'] tagline-md">In Real Time</p>
        </div>

        <div class="absolute z-10 bottom-0 left-0 mb-5 ml-5 hidden lg:block">
          <p class="text-white text-5xl font-bold font-['Space Grotesk'] tagline-lg">Meet Real People</p>
          <p class="text-white text-5xl font-bold font-['Space Grotesk'] tagline-lg">In Real Time</p>
        </div>
      </div>

      <div class="w-screen h-screen object-cover flex flex-col items-center gap-y-5">
        <p class="font-semibold text-4xl pt-20">How does it work?</p>  
        <div class="carousel-container">
          <explanation-carousel></explanation-carousel>
        </div>        
        <button class="main-bg-color rounded text-white w-52 p-3 uppercase text-2xl font-semibold cursor-pointer" (click)="isModalVisible = true">Start</button>
      </div>
      <ng-container *ngIf="isModalVisible">
        <lib-email-entry (closeModalEvent)="onCloseModal()"></lib-email-entry>
      </ng-container>
  `,
  styles: [`
    .tagline-sm {
      color: #179EB4;
      background: linear-gradient(to right, white, #179EB4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .tagline-md {
      color: #127180;
      background: linear-gradient(to right, white, #127180);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }   
    
    .tagline-lg {
      color: white;
      background: linear-gradient(to right, white, #91e1ed);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .carousel-container {
      @media (max-width: 1050px) {
        max-width: 1000px;
      }      
      @media (max-width: 768px) {
        max-width: 700px;
      }
      @media (max-width: 540px) {
        max-width: 500px;
      }
      @media (max-width: 500px) {
        max-width: 340px;
      }
    }
  `]
})
export class HomeComponent {
  isModalVisible = false;
  constructor(private readonly router: Router) {}
  onCloseModal() {
    this.isModalVisible = false;
    this.router.navigate(['connections']);
  }
}
